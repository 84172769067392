import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ServerAutoSuggest from "../ServerAutoSuggest";

const dflt = [];
const getMandatoryEnv = require("../../../utils/getMandatoryEnv");

const getPostalCode = async (input) => {
  if (typeof input === "string" && input.endsWith("^")) {
    const s = input.split("^");
    const o = { id: s[0], pincode: s[0], state: "", country: "" };
    return Promise.resolve(o);
  }
  const o = { id: input || "", pincode: input || "", state: "", country: "" };
  return Promise.resolve(o);
};

const Postal = ({ city, includeOnly, edit, ...restprops }) => {
  const { mySrcmConfig } = getMandatoryEnv(["mySrcmConfig"]);
  const getConfigsObj = useMemo(() => {
    const config = {
      dflt: includeOnly ? dflt.filter(includeOnly) : dflt,
      fetch: getPostalCode,
      // Trigger suggestions
      getSuggestionValue: (suggestion) => {
        const selectedPincode =
          suggestion?.pincode ||
          (suggestion?.results?.length > 0 && suggestion.results[0]?.pincode) ||
          "";
        return `${typeof selectedPincode === "string" ? selectedPincode : ""}`;
      },
      // Render Each Option
      renderSuggestion: (suggestion) => suggestion.pincode,
      api: (itrimValue) =>
        `${mySrcmConfig?.profileServer}/api/v2/pincodes/${
          city ? `?city_id=${city?.id}&` : `?`
        }pincode__startswith=${itrimValue}`,
      data2resultsAsync: async (
        data,
        itrimValue /* getSuggestionValue, dflt */
      ) => {
        if (!!itrimValue && data?.results?.length === 0) {
          return [
            {
              id: `${itrimValue}^^`,
              pincode: itrimValue,
            },
          ];
        }
        return [...data.results];
      },
    };

    return config;
  }, [city, includeOnly, mySrcmConfig]);
  return <ServerAutoSuggest {...restprops} config={getConfigsObj} />;
};

Postal.propTypes = {
  country: PropTypes.string,
  edit: PropTypes.bool,
  includeOnly: PropTypes.func,
  city: PropTypes.objectOf(PropTypes.any),
};

Postal.defaultProps = {
  country: "",
  edit: false,
  includeOnly: null,
  city: { id: "", name: "" },
};

export default Postal;
