/** @jsx jsx */
import "react-phone-input-2/lib/style.css";
import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { useThemeUI, Flex, jsx } from "theme-ui";
import { noop } from "../../../utils/lodash";
import Icon from "../Icon";

const MyPhoneInput = ({ onChange, renderPrint, value, ...props }) => {
  const { theme } = useThemeUI();

  const { background = "white", text = "black" } = theme.colors || {};

  const phoneInput = React.useMemo(
    () => ({
      onChange,
      buttonStyle: {
        background,
        border: 0,
      },
      inputStyle: {
        height: "36px",
        fontSize: "16px",
        border: "none",
        width: "100%",
        outline: "none",
        background,
        color: text,
      },
      dropdownStyle: {
        background,
        color: text,
      },
      ...props,
    }),
    [onChange, background, props, text]
  );

  const flex = React.useMemo(
    () => ({
      sx: {
        width: "100%",
        outline: "0 !important",
        borderRadius: 0,
        ".react-tel-input .flag-dropdown": {
          border: 0,
          ".selected-flag": {
            background: `${background} !important`,
            "&:hover": {
              background: `${background} !important`,
            },
          },
        },
        ".form-control:focus": {
          boxShadow: "unset",
        },
        "div, input": {
          borderRadius: 0,
        },

        border: "1px solid transparent",
        borderColor: "#605E5C",
        // pr: 1,
      },
    }),
    [background]
  );

  const icon = React.useMemo(
    () => ({
      variant: "icons.variant1",
    }),
    []
  );
  if (renderPrint) {
    return renderPrint(value);
  }

  return (
    <Flex {...flex}>
      <PhoneInput {...phoneInput} value={value} />
      <Flex sx={{ bg: "background", pr: 1 }}>
        <Icon icon="phone" {...icon} />
      </Flex>
    </Flex>
  );
};

MyPhoneInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  renderPrint: PropTypes.func,
};

MyPhoneInput.defaultProps = {
  onChange: noop,
  value: "",
  renderPrint: null,
};

export default MyPhoneInput;
